import { computePosition, flip, offset } from '@floating-ui/dom';

export default function initFloatingLabels() {
    const badges = Array.from(document.querySelectorAll<HTMLElement>('.js-badge-with-tooltip'));

    const createTooltip = (text: string, className?: string) => {
        const tooltip = document.createElement('div');
        tooltip.classList.add('tooltip');
        tooltip.classList.add('text-button-3');
        if (className) {
            tooltip.classList.add(className);
        }
        tooltip.innerHTML = text;

        return tooltip;
    };

    badges.forEach((badge) => {
        const text = badge.dataset.label;
        if (text) {
            let timer: NodeJS.Timeout;
            let tooltip: HTMLElement | undefined | null;

            function update(tooltip: HTMLElement) {
                if (tooltip) {
                    computePosition(badge, tooltip, {
                        placement: 'bottom',
                        middleware: [offset(10), flip()],
                    }).then(({ x, y }) => {
                        Object.assign(tooltip.style, {
                            left: `${x}px`,
                            top: `${y}px`,
                        });
                    });
                }
            }

            function showTooltip() {
                if (!tooltip) {
                    tooltip = createTooltip(
                        badge.dataset.label || '',
                        badge.classList.contains('js-badge-tooltip-light') ? 'tooltip--light' : undefined,
                    );
                    document.body.appendChild(tooltip);
                }
                clearTimeout(timer);
                tooltip.classList.remove('is-out');
                tooltip.style.display = 'block';
                update(tooltip);
            }

            function hideTooltip() {
                if (tooltip) {
                    tooltip.classList.add('is-out');

                    timer = setTimeout(() => {
                        tooltip?.remove();
                        tooltip = null;
                    }, 400);
                }
            }

            badge.addEventListener('mouseenter', showTooltip);
            badge.addEventListener('mouseleave', hideTooltip);
            badge.addEventListener('focus', showTooltip);
            badge.addEventListener('blur', hideTooltip);
        }
    });
}
