import { gsap } from 'gsap';
import throttle from 'lodash.throttle';
import { lockBodyScroll, unlockBodyScroll } from '../utils/dom';
import { clamp } from '../utils/math';

const NAVBAR_POPUP_SELECTOR = '.js-nav-bar-popup';
const NAVBAR_POPUP_VISIBLE_CLASS = 'is-visible';
const NAVBAR_TOGGLER_SELECTOR = '.js-nav-bar-toggle-btn';
const NAVBAR_LINK_ACTIVE_CLASS = 'menu-link--active';

function init() {
    const header = document.querySelector('.js-header');
    const navbar = document.querySelector('.js-nav-bar');
    const themeSwitcher = document.querySelector('.js-theme-switcher');
    const navbarPopup = document.querySelector(NAVBAR_POPUP_SELECTOR);
    const toggler = document.querySelector(NAVBAR_TOGGLER_SELECTOR);
    const overlay = document.querySelector('.js-nav-bar-popup-overlay');
    const handle = document.querySelector('.js-nav-bar-popup-handle');
    const innerEl = document.querySelector<HTMLElement>('.js-nav-bar-popup-inner');

    const listPageNav = document.querySelector<HTMLElement>('.js-list-page-nav');
    const detailActions = document.querySelector('.js-detail-sticky-actions');

    let tween: gsap.core.Tween | null;
    let timeout: NodeJS.Timeout;

    let touchStartY = 0;
    let touchY = 0;
    let translateAllowed = false;

    if (themeSwitcher && navbarPopup) {
        const themeSwitcherClone = themeSwitcher.cloneNode(true);
        navbarPopup.appendChild(themeSwitcherClone);
    }

    const openPopup = () => {
        clearTimeout(timeout);

        if (innerEl) {
            innerEl.style.transform = '';
        }

        toggler?.classList.add(NAVBAR_LINK_ACTIVE_CLASS);
        navbarPopup?.classList.add(NAVBAR_POPUP_VISIBLE_CLASS);
        lockBodyScroll();
    };

    const closePopup = () => {
        toggler?.classList.remove(NAVBAR_LINK_ACTIVE_CLASS);
        navbarPopup?.classList.remove(NAVBAR_POPUP_VISIBLE_CLASS);
        unlockBodyScroll();
    };

    overlay?.addEventListener('click', closePopup);
    toggler?.addEventListener('click', () => {
        if (navbarPopup) {
            if (navbarPopup.classList.contains(NAVBAR_POPUP_VISIBLE_CLASS)) {
                closePopup();
            } else {
                openPopup();
            }
        }
    });

    /**
     * Закрываем попап по клику снаружи
     */
    document.addEventListener('click', (event) => {
        const { target } = event;

        if (
            target instanceof Element &&
            !target.closest(NAVBAR_POPUP_SELECTOR) &&
            !target.closest(NAVBAR_TOGGLER_SELECTOR)
        ) {
            navbarPopup?.classList.remove(NAVBAR_POPUP_VISIBLE_CLASS);
        }
    });

    if (handle) {
        handle.addEventListener('touchstart', (event) => {
            if (event instanceof TouchEvent) {
                translateAllowed = true;
                touchStartY = event.touches[0].clientY;

                if (innerEl) {
                    innerEl.style.transition = 'none';
                    innerEl.style.willChange = 'transform';
                }
            }
        });

        handle.addEventListener('touchmove', (event) => {
            if (translateAllowed && innerEl && event instanceof TouchEvent) {
                if (tween) {
                    tween.pause();
                    tween = null;
                }

                touchY = clamp((event.changedTouches[0].clientY - touchStartY) / 3, 0, 200);
                innerEl.style.transform = `translate3d(0px, ${touchY}px, 0px)`;
            }
        });

        handle.addEventListener('touchend', () => {
            if (translateAllowed) {
                if (touchY > 20) {
                    closePopup();

                    if (innerEl) {
                        innerEl.style.willChange = '';
                    }
                } else if (innerEl) {
                    tween = gsap.to(innerEl, {
                        duration: 0.5,
                        y: 0,
                        overwrite: true,
                        onComplete: () => {
                            gsap.set(innerEl, { clearProps: 'all' });
                            tween = null;
                        },
                    });
                }

                touchStartY = 0;
                translateAllowed = false;
            }
        });
    }

    /**
     * Показать/скрыть навбар по скроллу
     */
    let prevScrollY = window.scrollY;
    let deltaScrollY = 0;

    const onScroll = () => {
        if (navbar) {
            const { scrollY } = window;
            deltaScrollY = Math.abs(scrollY - prevScrollY);

            if (deltaScrollY > 20) {
                if (scrollY > prevScrollY && scrollY >= 0) {
                    document.documentElement.classList.remove('bars-visible');
                    navbar.classList.remove(NAVBAR_POPUP_VISIBLE_CLASS);
                    listPageNav?.classList.remove('is-visible');
                    detailActions?.classList.remove('is-active');
                    header?.classList.add('is-hidden');
                } else {
                    document.documentElement.classList.add('bars-visible');
                    navbar.classList.add(NAVBAR_POPUP_VISIBLE_CLASS);
                    listPageNav?.classList.add('is-visible');
                    detailActions?.classList.add('is-active');
                    header?.classList.remove('is-hidden');
                }
            }

            prevScrollY = scrollY;
        }
    };

    document.documentElement.classList.add('bars-visible');
    document.addEventListener('scroll', throttle(onScroll, 100));
}

export default { init };
