const LOCALSTORAGE_NAME = 'APP_THEME';

function init() {
    Array.from(document.querySelectorAll('.js-theme-switcher')).forEach((el) => {
        const input = el.querySelector('input');

        if (input) {
            if (localStorage.getItem(LOCALSTORAGE_NAME) === 'dark') {
                input.checked = true;
            }

            input.addEventListener('change', (event) => {
                const { target } = event;

                if (target instanceof HTMLInputElement) {
                    if (target.checked) {
                        document.documentElement.classList.remove('theme-light');
                        document.documentElement.classList.add('theme-dark');
                        localStorage.setItem(LOCALSTORAGE_NAME, 'dark');
                    } else {
                        document.documentElement.classList.remove('theme-dark');
                        document.documentElement.classList.add('theme-light');
                        localStorage.setItem(LOCALSTORAGE_NAME, 'light');
                    }

                    Array.from(document.querySelectorAll<HTMLInputElement>('.js-theme-switcher input')).forEach(
                        (input) => {
                            input.checked = target.checked;
                        },
                    );
                }
            });
        }
    });
}

export default { init };
