import debounce from 'lodash.debounce';

export default function initSearchFilter() {
    const searchWrappers = Array.from(document.querySelectorAll('.js-filter-search-wrapper'));

    searchWrappers.forEach((wrapper) => {
        const input = wrapper.querySelector<HTMLInputElement>('.js-filter-search-input');
        const items = Array.from(wrapper.querySelectorAll<HTMLElement>('.js-filter-search-item'));

        const onInput = (event: Event) => {
            const eventTarget = event.target as HTMLInputElement;
            const value = eventTarget.value.toUpperCase();

            wrapper.dispatchEvent(new Event('search-updated'));

            items.forEach((item) => {
                if (item.dataset.value) {
                    item.dataset.value.toUpperCase().includes(value)
                        ? item.removeAttribute('hidden')
                        : item.setAttribute('hidden', '');
                }
            });
        };

        if (input) {
            input.addEventListener(
                'input',
                debounce((event: Event) => {
                    onInput(event);
                }, 100),
            );
        }
    });
}
