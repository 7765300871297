import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

function init(container: HTMLElement | Document = document) {
    const progressContainer = document.querySelector<HTMLElement>('.js-detail-sticky-info');
    const progressBlock = container.querySelector<HTMLElement>('.js-detail-progress-block');
    const progressBar = container.querySelector<HTMLElement>('.js-detail-progress-bar');
    const progressElements = Array.from(container.querySelectorAll<HTMLElement>('.js-detail-progress-block-el'));
    const links = Array.from(container.querySelectorAll<HTMLAnchorElement>('a.js-detail-progress-link'));

    if (progressBlock) {
        document.addEventListener('scroll', () => {
            if (progressContainer) {
                if (window.scrollY > 120) {
                    progressContainer.classList.add('is-scroll-enough-to-be-visible');
                } else {
                    progressContainer.classList.remove('is-scroll-enough-to-be-visible');
                }
            }
        });

        const st = ScrollTrigger.create({
            trigger: progressBlock,
            start: 'top bottom',
            end: 'bottom bottom',
            onEnter: () => {
                progressContainer?.classList.add('is-visible');
            },
            onEnterBack: () => {
                progressContainer?.classList.add('is-visible');
            },
            onLeave: () => {
                progressContainer?.classList.remove('is-visible');
            },
            onLeaveBack: () => {
                progressContainer?.classList.remove('is-visible');
            },
            onUpdate: ({ progress }) => {
                if (progressBar) {
                    const radius = matchMedia('(max-width: 1199px)').matches ? 0 : 15;
                    const fx = progress;
                    const fy = 1;
                    progressBar.style.transform = `scaleX(${progress})`;
                    progressBar.style.borderRadius = `${radius / fx}px / ${radius / fy}px`;
                }
            },
        });

        if ('ResizeObserver' in window) {
            const ro = new ResizeObserver(() => {
                st.update();
                st.refresh();
            });

            ro.observe(document.body);
        }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const { target } = entry;

                    if (target instanceof HTMLElement) {
                        if (entry.isIntersecting) {
                            links.forEach((link) => {
                                link.classList.remove('is-active');
                            });
                            links
                                .find((link) => link.dataset.sectionTitle === target.dataset.title)
                                ?.classList.add('is-active');
                        }
                    }
                });
            },
            { rootMargin: '-50% 0% -50% 0%', threshold: [0, 1] },
        );

        progressElements.forEach((el) => {
            observer.observe(el);
        });

        links.forEach((link) => {
            link.addEventListener('click', (event) => {
                event.preventDefault();
                const href = link.getAttribute('href');

                if (href) {
                    const elToScroll = document.querySelector(href);
                    const headerHeight = 58;

                    if (elToScroll) {
                        gsap.to(window, {
                            duration: 1,
                            scrollTo: {
                                y: elToScroll,
                                offsetY: 60 + headerHeight,
                            },
                            ease: 'power3.out',
                        });
                    }
                }
            });
        });
    }
}

export default { init };
