import { lerp } from '../utils/lerp';

export default function initCursor() {
    const cursorZones = Array.from(document.querySelectorAll<HTMLElement>('.js-cursor-zone'));

    cursorZones.forEach((zone) => {
        const cursorSlug = zone.dataset.cursorSlug;
        const cursor = document.querySelector<HTMLElement>(`.js-cursor[data-cursor-slug="${cursorSlug}"]`);
        let rAF: number;

        const mouse = {
            x: 0,
            y: 0,
        };

        const lerpedMouse = {
            x: 0,
            y: 0,
        };

        if (!cursor) {
            return;
        }

        const onPointerEnter = () => {
            cursor.classList.add('is-visible');
            rAF = requestAnimationFrame(animate);
        };

        const onPointerLeave = () => {
            cursor.classList.remove('is-visible');
            if (rAF) {
                cancelAnimationFrame(rAF);
            }
        };

        const onPointerMove = (event: PointerEvent) => {
            mouse.x = event.clientX;
            mouse.y = event.clientY;
        };

        function render() {
            lerpedMouse.x = lerp(lerpedMouse.x, mouse.x, 0.1);
            lerpedMouse.y = lerp(lerpedMouse.y, mouse.y, 0.1);

            if (cursor) {
                cursor.style.transform = `translate3d(${lerpedMouse.x}px, ${lerpedMouse.y}px, 0) translate3d(-50%, -50%, 0)`;
            }
        }

        function animate() {
            render();
            rAF = requestAnimationFrame(animate);
        }

        zone.addEventListener('pointerenter', onPointerEnter);
        zone.addEventListener('pointerleave', onPointerLeave);
        zone.addEventListener('pointermove', onPointerMove);
    });
}
