import { wrap } from '../utils/dom';

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.wysiwyg')).forEach((el) => {
        /**
         * Links
         */
        Array.from(el.querySelectorAll<HTMLAnchorElement>('a:not([class])')).forEach((item) => {
            const text = item.textContent;

            if (text) {
                item.classList.add('link');
                item.dataset.hover = text;
                const innerEl = document.createElement('div');
                innerEl.className = 'link__text';
                innerEl.textContent = text;
                item.textContent = '';
                item.appendChild(innerEl);
            }
        });

        /**
         * Tables
         */
        Array.from(el.querySelectorAll<HTMLTableElement>('table')).forEach((item) => {
            const wrapper = document.createElement('div');
            wrapper.className = 'table-responsive';
            wrap(item, wrapper);
        });
    });
}

export default { init };
