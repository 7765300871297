import mobileAppLinkModals from './mobile-app-like-modals';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

function init() {
    Array.from(document.querySelectorAll<LitPopupElement>('app-lit-popup.js-mobile-app-like-modal')).forEach(
        (popupEl) => {
            mobileAppLinkModals.init(popupEl, () => popupEl.close(), '(max-width: 767px)');

            popupEl.addEventListener('click', (event) => {
                if (event.target instanceof HTMLElement && !event.target.closest('.js-dialog-el')) {
                    popupEl.close();
                }
            });
        },
    );
}

export default { init };
