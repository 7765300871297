import axios from 'axios';
import debounce from 'lodash.debounce';

const ACTIVE_FORM_CLASS = 'search--suggestions';

function init() {
    const form = document.querySelector<HTMLFormElement>('form.js-search');
    const input = form?.querySelector<HTMLInputElement>('input.js-search-input');
    const resetBtn = form?.querySelector<HTMLInputElement>('button.js-search-clear-btn');
    const contentEl = document.querySelector('.js-search-suggestions');
    const tips = Array.from(document.querySelectorAll<HTMLElement>('.js-search-tip-btn'));
    const loader = form?.querySelector<HTMLElement>('.js-search-loader');
    const MIN_SYMBOLS = 3;

    if (form && input) {
        const endpoint = form.dataset.suggestionsEndpoint || '';
        let controller: AbortController | null;
        let loaderTimeout: NodeJS.Timeout;

        const fetchData = async () => {
            clearTimeout(loaderTimeout);
            controller = new AbortController();
            const value = input.value.trim();

            loaderTimeout = setTimeout(() => {
                if (loader) {
                    loader.hidden = false;
                }

                if (resetBtn) {
                    resetBtn.hidden = true;
                }
            }, 200);

            try {
                const { data } = await axios.get(endpoint, {
                    params: {
                        [input.name]: value,
                    },
                    signal: controller.signal,
                });

                if (contentEl) {
                    contentEl.innerHTML = data.data.html || '';

                    if (input.value.trim().length >= MIN_SYMBOLS) {
                        form.classList.add(ACTIVE_FORM_CLASS);
                    } else {
                        form.classList.remove(ACTIVE_FORM_CLASS);
                    }
                }
            } catch (err) {
                if (err instanceof Error) {
                    if (err.name !== 'CanceledError') {
                        throw err;
                    }
                }
            } finally {
                controller = null;
                clearTimeout(loaderTimeout);

                if (loader) {
                    loader.hidden = true;
                }

                if (resetBtn) {
                    resetBtn.hidden = false;
                }
            }
        };

        const debouncedFetchData = debounce(fetchData, 500);

        const onInput = () => {
            if (controller) {
                controller.abort();
                controller = null;
            }

            const value = input.value.trim();

            if (value.length >= MIN_SYMBOLS) {
                debouncedFetchData();
            } else {
                form.classList.remove(ACTIVE_FORM_CLASS);
            }
        };

        input?.addEventListener('input', onInput);
    }

    tips.forEach((el) => {
        el.addEventListener('click', () => {
            const { value } = el.dataset;

            if (input && value) {
                input.value = value;
            }
        });
    });

    resetBtn?.addEventListener('click', (event) => {
        event.preventDefault();

        if (input) {
            input.value = '';
        }
    });
}

export default { init };
