import './webpack-imports';
import './sentry';
import './custom-elements';
import './components/lazy-load';
import { applyPolyfills } from './polyfills';
import navbar from './components/nav-bar';
import themeSwitcher from './components/theme-switcher';
import dialogMobileAppLinkModals from './components/dialog-mobile-app-like-modals';
import initCursor from './components/cursor';
import initFloatingLabels from './components/floating-labels';
import searchPopup from './components/search-popup';
import searchForm from './components/search-form';
import { initSliderInReviewCard } from './components/slider-in-review-card';
import collapsedText from './components/collapsed-text';
import dropdown from './components/dropdown';
import gallery from './components/gallery';
import wysiwyg from './components/wysiwyg';
import detailProgress from './components/detail-progress';
import marqueeImagesInit from './inits/marquee-images';
import initSearchFilter from './components/search-filter';
import initFilterCollapse from './components/filter-collapse';
import asideMobileAppLinkModals from './components/aside-mobile-app-like-modals';
import copy from './components/copy';
import detailShort from './components/detail-short';
import mapPageInit from './inits/map-page';
import restaurantsdetailContentGallerySlider from './components/restaurants-detail-content-gallery-slider';
import restaurantsdetailMenuGallerySlider from './components/restaurants-detail-menu-gallery-slider';
import bookRestShadow from './components/book-rest-shadow';
import bookRestStick from './components/book-rest-stick';
import dishes from './components/dishes';
import searchSuggestions from './components/search-suggestions';

document.addEventListener('DOMContentLoaded', () => {
    document.documentElement.classList.add('js-ready');

    applyPolyfills().then(() => {
        navbar.init();
        themeSwitcher.init(); // должен быть после "navbar"
        dialogMobileAppLinkModals.init();
        initCursor();
        initFloatingLabels();
        searchPopup.init();
        searchForm.init();
        initSliderInReviewCard();
        collapsedText.init();
        dropdown.init();
        gallery.init();
        wysiwyg.init();
        detailProgress.init();
        marqueeImagesInit();
        initSearchFilter();
        initFilterCollapse();
        asideMobileAppLinkModals.init();
        copy.init();
        detailShort.init();
        mapPageInit();
        restaurantsdetailContentGallerySlider.init();
        restaurantsdetailMenuGallerySlider.init();
        bookRestShadow.init();
        bookRestStick.init();
        dishes.init();
        searchSuggestions.init();
    });
});
