/* eslint-disable max-len, max-lines */
// import './WebShare.scss';
import { LitElement, html, css, unsafeCSS, TemplateResult } from 'lit';
import { ok, mail, viber, whatsapp, telegram, tw, vk } from 'vanilla-sharing';
// import _styles from './WebShare.ce.scss';
import { isMobileDevice } from '../../utils/mobile';
import loadScript from '../../../modules/load-script';

export interface WebShare {
    /**
     * Шерить ли в Facebook
     */
    facebook: boolean;

    /**
     * App ID для Facebook (получается в настройках бизнес-аккаунта FB)
     */
    facebookAppId: string | null;

    /**
     * Показывает, загружен ли Facebook SDK
     */
    facebookReady: boolean;

    /**
     * Шерить ли в Mail.ru
     */
    mail: boolean;

    /**
     * Использовать ли Web Share API (если доступно)
     */
    native: boolean;

    /**
     * Шерить ли в Одноклассники
     */
    ok: boolean;

    /**
     * Шерить ли в Telegram
     */
    telegram: boolean;

    /**
     * Заголовок для шеринга
     */
    title: string;

    /**
     * Шерить ли в Twitter
     */
    twitter: boolean;

    /**
     * Шерить ли в Skype
     */
    skype: boolean;

    /**
     * Показывает, загружен ли Skype SDK
     */
    skypeReady: boolean;

    /**
     * URL, который шерим
     */
    url: string;

    /**
     * Шерить ли в Viber
     */
    viber: boolean;

    /**
     * Шерить ли в VK
     */
    vk: boolean;

    /**
     * Шерить ли в Whatsapp
     */
    whatsapp: boolean;

    /**
     * Вычисленный boolean,
     * показывающий реальную возможность использовать WebShare API
     */
    _useNative: boolean;
}

function shareFacebook(url: string) {
    if ((window as any).FB) {
        (window as any).FB.ui({
            method: 'share',
            href: url,
        });
    }
}

function shareVK(url: string) {
    vk({
        url,
        title: document.title,
    });
}

function shareTwitter(url: string) {
    tw({
        url,
        title: document.title,
    });
}

function shareOK(url: string) {
    ok({
        url,
        title: document.title,
    });
}

function shareMail(url: string) {
    mail({
        url,
        title: document.title,
    });
}

function shareViber(url: string) {
    viber({
        url,
        title: document.title,
    });
}

function shareWhatsapp(url: string) {
    whatsapp({
        url,
        title: document.title,
    });
}

function shareTelegram(url: string) {
    telegram({
        url,
        title: document.title,
    });
}

function shareSkype(url: string) {
    telegram({
        url,
        title: document.title,
    });
}

function loadSkypeSharingSDK(): Promise<void> {
    return new Promise((resolve) => {
        ((r, d, s) => {
            (r as any).loadSkypeWebSdkAsync =
                (r as any).loadSkypeWebSdkAsync ||
                function fn(p: any) {
                    const sjs = d.getElementsByTagName(s)[0] as any;
                    if (d.getElementById(p.id)) {
                        return;
                    }
                    const js = d.createElement(s) as HTMLScriptElement;
                    js.id = p.id;
                    js.src = p.scriptToLoad;
                    js.onload = p.callback;
                    sjs.parentNode.insertBefore(js, sjs);
                };
            const p = {
                scriptToLoad: 'https://swx.cdn.skype.com/shared/v/latest/skypewebsdk.js',
                id: 'skype_web_sdk',
                callback: resolve,
            };
            (r as any).loadSkypeWebSdkAsync(p);
        })(window, document, 'script');
    });
}

async function loadFacebookSharingSDK(fbAppId?: string | null) {
    if (!fbAppId) {
        console.error('Invalid Facebook App ID');
    }

    await loadScript(`https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0&appId=${fbAppId}`);
}

export class WebShare extends LitElement {
    constructor() {
        super();
        this.share = this.share.bind(this);
        this._closeOnOutsideClick = this._closeOnOutsideClick.bind(this);

        this._useNative = false;
        this.native = false;

        this.title = document.title;
        this.url = window.location.href;

        this.facebookReady = false;
        this.skypeReady = false;

        this.facebook = false;
        this.mail = false;
        this.ok = false;
        this.telegram = false;
        this.twitter = false;
        this.skype = false;
        this.viber = false;
        this.vk = false;
        this.whatsapp = false;

        this.facebookAppId = this.getAttribute('facebook-app-id');
    }

    // static get styles() {
    //     return css`
    //         ${unsafeCSS(_styles)}
    //     `;
    // }

    createRenderRoot() {
        /**
         * Render template without shadow DOM. Note that shadow DOM features like
         * encapsulated CSS and slots are unavailable.
         */
        return this;
    }

    static get properties() {
        return {
            facebook: {
                type: Boolean,
            },
            facebookAppId: {
                type: String,
            },
            mail: {
                type: Boolean,
            },
            native: {
                type: Boolean,
            },
            ok: {
                type: Boolean,
            },
            telegram: {
                type: Boolean,
            },
            twitter: {
                type: Boolean,
            },
            skype: {
                type: Boolean,
            },
            viber: {
                type: Boolean,
            },
            vk: {
                type: Boolean,
            },
            whatsapp: {
                type: Boolean,
            },
            _useNative: {
                type: Boolean,
            },
        };
    }

    connectedCallback() {
        super.connectedCallback();
        this._useNative = this.native && 'share' in navigator && isMobileDevice();

        if (!this._useNative) {
            if (this.facebook) {
                loadFacebookSharingSDK(this.facebookAppId).then(() => {
                    this.facebookReady = true;
                });
            }

            if (this.skype) {
                loadSkypeSharingSDK().then(() => {
                    this.skypeReady = true;
                });
            }
        }

        document.addEventListener('click', this._closeOnOutsideClick);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener('click', this._closeOnOutsideClick);
    }

    async share() {
        if (this._useNative) {
            await navigator.share({ text: '', title: this.title, url: this.url });
        } else {
            this.renderRoot.querySelector('.list')?.classList.toggle('is-open');
        }
    }

    private _closeOnOutsideClick(event: any) {
        const el = event.target.closest('app-web-share');

        if (!el) {
            this.renderRoot.querySelector('.list')?.classList.remove('is-open');
        }
    }

    /**
     *
     * Lit-шаблон для элемента списка
     *
     * @param iconHtml Lit-шаблон SVG-иконки
     * @param label Название соц. сети
     * @param sharingFn Релевантная функция шеринга
     * @returns TemplateResult<1>
     */
    li(iconHtml: TemplateResult<1>, label: string, sharingFn: any) {
        return html`
            <li class="list__item">
                <button
                    type="button"
                    class="list-btn"
                    aria-label="Поделиться в ${label}"
                    @click="${() => sharingFn(this.url)}"
                >
                    ${iconHtml}
                </button>
            </li>
        `;
    }

    render() {
        return html`
            ${this._useNative
                ? html`<button
                      type="button"
                      class="list-btn ${this._useNative ? '' : 'list-btn--main'}"
                      aria-label="Поделиться"
                      @click="${this.share}"
                  >
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M16 0.5C14.0759 0.5 12.5 2.07589 12.5 4C12.5 4.29288 12.5993 4.55473 12.668 4.82715L6.67285 7.82617C6.02956 7.03697 5.09075 6.5 4 6.5C2.07589 6.5 0.5 8.07589 0.5 10C0.5 11.9241 2.07589 13.5 4 13.5C5.09075 13.5 6.02956 12.963 6.67285 12.1738L12.668 15.1729C12.5993 15.4453 12.5 15.7071 12.5 16C12.5 17.9241 14.0759 19.5 16 19.5C17.9241 19.5 19.5 17.9241 19.5 16C19.5 14.0759 17.9241 12.5 16 12.5C14.9092 12.5 13.9704 13.037 13.3271 13.8262L7.33203 10.8271C7.40073 10.5547 7.5 10.2929 7.5 10C7.5 9.70712 7.40073 9.44527 7.33203 9.17285L13.3271 6.17383C13.9704 6.96303 14.9092 7.5 16 7.5C17.9241 7.5 19.5 5.92411 19.5 4C19.5 2.07589 17.9241 0.5 16 0.5ZM16 2C17.1135 2 18 2.88655 18 4C18 5.11345 17.1135 6 16 6C14.8865 6 14 5.11345 14 4C14 2.88655 14.8865 2 16 2ZM4 8C5.11345 8 6 8.88655 6 10C6 11.1135 5.11345 12 4 12C2.88655 12 2 11.1135 2 10C2 8.88655 2.88655 8 4 8ZM16 14C17.1135 14 18 14.8865 18 16C18 17.1135 17.1135 18 16 18C14.8865 18 14 17.1135 14 16C14 14.8865 14.8865 14 16 14Z"
                              fill="#94A1BD"
                          />
                      </svg>
                  </button>`
                : html`
                      <ul class="list">
                          ${this.facebook && this.facebookReady && this.facebookAppId
                              ? this.li(
                                    html`<svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.00878906 8.88932C0.00878906 3.97988 3.98794 0 8.89646 0C13.805 0 17.7841 3.97988 17.7841 8.88932C17.7841 13.5996 14.1212 17.4542 9.48897 17.7592V10.6672H11.859V9.48194H9.48897V7.70407C9.48897 6.72219 10.2848 5.92621 11.2665 5.92621H11.859V4.74097H11.2665C9.63033 4.74097 8.30395 6.0676 8.30395 7.70407V9.48194H5.9339V10.6672H8.30395V17.7592C3.67168 17.4542 0.00878906 13.5996 0.00878906 8.88932Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Facebook',
                                    shareFacebook,
                                )
                              : ''}
                          ${this.vk
                              ? this.li(
                                    html`<svg
                                        width="26"
                                        height="15"
                                        viewBox="0 0 26 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon--vk"
                                    >
                                        <path
                                            d="M15.7438 7.17199C15.2684 6.99574 15.2684 6.32262 15.2389 5.85387C15.12 4.18418 15.7143 1.63512 15.0012 0.551367C14.4963 -0.0936333 12.0593 -0.0345709 10.574 0.0535541C10.1576 0.112617 9.65267 0.199805 9.29611 0.376055C8.93954 0.552305 8.58297 0.844804 8.58297 1.10824C8.58297 1.48887 9.47486 1.43074 9.80101 1.92855C10.1576 2.45637 10.1576 3.59824 10.1576 4.53574C10.1576 5.61949 9.97881 7.05574 9.53382 7.11387C8.85016 7.14293 8.46412 6.46887 8.10755 5.97105C7.39442 5.00449 6.68128 3.80262 6.14596 2.63074C5.87877 2.01574 5.72949 1.34168 5.34344 1.07824C4.74916 0.667617 3.67946 0.638555 2.63924 0.667617C1.68839 0.69668 0.321078 0.579492 0.0538908 1.13637C-0.154344 1.75137 0.291602 2.3373 0.529313 2.83605C1.74735 5.44324 3.05476 7.72887 4.65979 9.89637C6.14596 11.9176 7.5418 13.5292 10.2755 14.3786C11.0485 14.6129 14.4354 15.287 15.1191 14.3786C15.3568 14.027 15.2978 13.2357 15.4167 12.6498C15.5356 12.0639 15.6839 11.4779 16.2487 11.4489C16.7241 11.4198 16.9913 11.8295 17.2889 12.1229C17.616 12.4454 17.8832 12.7089 18.1209 13.0023C18.6857 13.5592 19.28 14.3204 19.9931 14.6139C20.9734 15.0245 22.4891 14.9073 23.9154 14.8482C25.0744 14.8192 25.9064 14.5848 25.9958 13.9107C26.0557 13.3829 25.4605 12.6217 25.1039 12.182C24.212 11.0982 23.7965 10.7757 22.7857 9.77918C22.3398 9.33949 21.775 8.87074 21.775 8.34387C21.7455 8.02137 22.0127 7.72887 22.2504 7.40637C23.2906 5.88293 24.3309 4.79918 25.2817 3.21668C25.5489 2.74793 26.1736 1.66418 25.9359 1.13637C25.6687 0.55043 24.1825 0.725742 23.2612 0.725742C22.0726 0.725742 20.5275 0.637617 20.2299 0.871992C19.6651 1.25262 19.4274 1.86855 19.1602 2.45449C18.5659 3.80262 17.7634 5.17887 16.9314 6.20449C16.6338 6.55605 16.0699 7.28824 15.7428 7.17105L15.7438 7.17199Z"
                                            fill="#4585ED"
                                        />
                                    </svg> `,
                                    'VK',
                                    shareVK,
                                )
                              : ''}
                          ${this.twitter
                              ? this.li(
                                    html`<svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.00878906 8.88932C0.00878906 3.97988 3.98794 0 8.89646 0C13.805 0 17.7841 3.97988 17.7841 8.88932C17.7841 13.5996 14.1212 17.4542 9.48897 17.7592V10.6672H11.859V9.48194H9.48897V7.70407C9.48897 6.72219 10.2848 5.92621 11.2665 5.92621H11.859V4.74097H11.2665C9.63033 4.74097 8.30395 6.0676 8.30395 7.70407V9.48194H5.9339V10.6672H8.30395V17.7592C3.67168 17.4542 0.00878906 13.5996 0.00878906 8.88932Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Twitter',
                                    shareTwitter,
                                )
                              : ''}
                          ${this.ok
                              ? this.li(
                                    html`<svg
                                        width="20"
                                        height="33"
                                        viewBox="0 0 20 33"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M10.0817 0.933594C5.61595 0.933594 1.99586 4.55342 1.99586 9.01856C1.99586 13.4838 5.61595 17.1039 10.0817 17.1039C14.5474 17.1039 18.1675 13.4838 18.1675 9.01856C18.1675 4.55342 14.5474 0.933594 10.0817 0.933594ZM10.0817 12.3609C8.2357 12.3609 6.73918 10.8645 6.73918 9.01868C6.73918 7.1729 8.2357 5.67653 10.0817 5.67653C11.9276 5.67653 13.4242 7.1729 13.4242 9.01868C13.4242 10.8645 11.9276 12.3609 10.0817 12.3609Z"
                                            fill="#F7931E"
                                        />
                                        <path
                                            d="M12.9366 23.5371C16.1737 22.8777 18.1135 21.3449 18.2161 21.2627C19.1634 20.5031 19.3155 19.1195 18.5559 18.1723C17.7963 17.2252 16.4127 17.073 15.4653 17.8325C15.4453 17.8487 13.3763 19.4356 9.97214 19.4379C6.56808 19.4356 4.45539 17.8487 4.43537 17.8325C3.48791 17.073 2.10433 17.2252 1.34479 18.1723C0.585132 19.1195 0.737296 20.5031 1.68452 21.2627C1.78855 21.3461 3.80844 22.9188 7.13624 23.5627L2.49834 28.4092C1.65548 29.2832 1.68082 30.6748 2.55492 31.5176C2.98155 31.9289 3.53142 32.1334 4.08083 32.1334C4.65685 32.1334 5.23229 31.9083 5.66367 31.4609L9.97225 26.8716L14.716 31.4907C15.5756 32.3487 16.9674 32.3473 17.8252 31.4882C18.6831 30.629 18.6821 29.2371 17.8228 28.3793L12.9366 23.5371Z"
                                            fill="#F7931E"
                                        />
                                    </svg> `,
                                    'Odnoklassniki',
                                    shareOK,
                                )
                              : ''}
                          ${this.telegram
                              ? this.li(
                                    html`<svg
                                        width="22"
                                        height="20"
                                        viewBox="0 0 22 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon--tg"
                                        style="position:relative;left:-5%;"
                                    >
                                        <path
                                            d="M21.9816 1.6621C22.1529 0.554563 21.0999 -0.31962 20.1151 0.112748L0.501623 8.72409C-0.204562 9.03415 -0.152903 10.1039 0.579517 10.3371L4.62425 11.6252C5.39632 11.8711 6.23218 11.7439 6.90634 11.2782L16.0256 4.97791C16.3006 4.78788 16.6004 5.17893 16.3654 5.42107L9.80112 12.1888C9.16436 12.8454 9.29072 13.9579 10.0567 14.4382L17.4061 19.0469C18.2304 19.5638 19.2908 19.0446 19.445 18.0484L21.9816 1.6621Z"
                                            fill="#039BE5"
                                        />
                                    </svg> `,
                                    'Telegram',
                                    shareTelegram,
                                )
                              : ''}
                          ${this.mail
                              ? this.li(
                                    html`<svg
                                        width="19"
                                        height="16"
                                        viewBox="0 0 19 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18.2985 0.772295C18.3331 0.559721 18.2553 0.344279 18.0942 0.20603C17.9331 0.0677815 17.7126 0.027394 17.5148 0.0998766L0.924484 6.17884C0.700955 6.26074 0.546639 6.4716 0.532333 6.71468C0.518026 6.95775 0.64649 7.18613 0.858788 7.29504L5.59888 9.72662C5.78906 9.82418 6.01561 9.80971 6.19252 9.6887L10.1273 6.99743L7.77123 10.0189C7.66749 10.152 7.62293 10.3236 7.64841 10.492C7.67388 10.6603 7.76706 10.81 7.90524 10.9045L15.0154 15.7677C15.1827 15.8821 15.3951 15.9017 15.5796 15.8197C15.7641 15.7377 15.8954 15.5653 15.9285 15.3618L18.2985 0.772295Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Mail',
                                    shareMail,
                                )
                              : ''}
                          ${this.viber
                              ? this.li(
                                    html`<svg
                                        width="19"
                                        height="16"
                                        viewBox="0 0 19 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18.2985 0.772295C18.3331 0.559721 18.2553 0.344279 18.0942 0.20603C17.9331 0.0677815 17.7126 0.027394 17.5148 0.0998766L0.924484 6.17884C0.700955 6.26074 0.546639 6.4716 0.532333 6.71468C0.518026 6.95775 0.64649 7.18613 0.858788 7.29504L5.59888 9.72662C5.78906 9.82418 6.01561 9.80971 6.19252 9.6887L10.1273 6.99743L7.77123 10.0189C7.66749 10.152 7.62293 10.3236 7.64841 10.492C7.67388 10.6603 7.76706 10.81 7.90524 10.9045L15.0154 15.7677C15.1827 15.8821 15.3951 15.9017 15.5796 15.8197C15.7641 15.7377 15.8954 15.5653 15.9285 15.3618L18.2985 0.772295Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Viber',
                                    shareViber,
                                )
                              : ''}
                          ${this.whatsapp
                              ? this.li(
                                    html`<svg
                                        width="42"
                                        height="42"
                                        viewBox="0 0 42 42"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g opacity="0.2" filter="url(#filter0_f_845_30918)">
                                            <path
                                                d="M12.8215 33.7833L13.3864 34.1179C15.7591 35.5254 18.4796 36.27 21.2539 36.2712H21.2598C29.7797 36.2712 36.7136 29.3419 36.7171 20.8251C36.7186 16.6979 35.1123 12.8169 32.1939 9.89741C30.7625 8.45762 29.0596 7.31592 27.1838 6.53841C25.308 5.76089 23.2965 5.36301 21.2658 5.36778C12.7393 5.36778 5.8052 12.2964 5.80217 20.8127C5.79797 23.7208 6.61724 26.5707 8.16527 29.0329L8.53304 29.617L6.97121 35.3165L12.8215 33.7833ZM2.50586 39.7319L5.14447 30.1021C3.51717 27.284 2.66106 24.0869 2.66207 20.8114C2.66637 10.5647 11.0086 2.22852 21.26 2.22852C26.2347 2.23104 30.904 4.16614 34.4157 7.67883C37.9273 11.1915 39.8595 15.8606 39.8577 20.8263C39.8531 31.0723 31.5096 39.41 21.2598 39.41H21.2517C18.1394 39.4087 15.0812 38.6283 12.3647 37.1477L2.50586 39.7319Z"
                                                fill="#B3B3B3"
                                            />
                                        </g>
                                        <path
                                            d="M2.32617 39.5366L4.96479 29.9068C3.33468 27.0819 2.47844 23.8773 2.48238 20.6161C2.48668 10.3694 10.8289 2.0332 21.0803 2.0332C26.055 2.03573 30.7243 3.97083 34.236 7.48352C37.7476 10.9962 39.6798 15.6653 39.678 20.631C39.6735 30.877 31.3299 39.2147 21.0801 39.2147H21.072C17.9597 39.2134 14.9015 38.433 12.185 36.9524L2.32617 39.5366Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M21.073 5.17582C12.5465 5.17582 5.61242 12.1044 5.60939 20.6207C5.60519 23.5288 6.42446 26.3787 7.97249 28.8409L8.34026 29.4253L6.77843 35.1248L12.6289 33.5914L13.1939 33.9259C15.5666 35.3335 18.2871 36.0778 21.0614 36.0793H21.0672C29.5872 36.0793 36.5213 29.1499 36.5246 20.6331C36.531 18.6032 36.1345 16.5921 35.3581 14.7164C34.5817 12.8406 33.4408 11.1374 32.0013 9.70546C30.5699 8.26564 28.867 7.12392 26.9911 6.34641C25.1153 5.56889 23.1038 5.17102 21.073 5.17582Z"
                                            fill="url(#paint0_linear_845_30918)"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M16.4231 12.8504C16.0748 12.077 15.7083 12.0614 15.3774 12.048L14.4867 12.0371C14.1768 12.0371 13.6733 12.1533 13.2476 12.6182C12.822 13.0831 11.6211 14.2067 11.6211 16.492C11.6211 18.7772 13.2863 20.9855 13.5183 21.2957C13.7504 21.606 16.733 26.4451 21.4564 28.3069C25.3816 29.8542 26.1804 29.5465 27.0327 29.4692C27.885 29.3919 29.782 28.3456 30.169 27.2609C30.556 26.1763 30.5562 25.247 30.4402 25.0527C30.3242 24.8584 30.0143 24.743 29.5492 24.5105C29.0841 24.2781 26.7999 23.1545 26.374 22.9994C25.9481 22.8443 25.6384 22.7672 25.3283 23.2321C25.0181 23.697 24.1287 24.7427 23.8574 25.0527C23.5862 25.3627 23.3155 25.4016 22.8504 25.1695C22.3853 24.9373 20.889 24.4466 19.1136 22.8642C17.7322 21.633 16.7997 20.1125 16.5283 19.6479C16.2568 19.1833 16.4995 18.9316 16.7325 18.7002C16.941 18.492 17.1971 18.1577 17.4299 17.8866C17.6627 17.6155 17.7393 17.4217 17.894 17.1122C18.0487 16.8027 17.9716 16.5309 17.8553 16.2987C17.739 16.0665 16.8361 13.7691 16.4231 12.8504Z"
                                            fill="white"
                                        />
                                        <defs>
                                            <filter
                                                id="filter0_f_845_30918"
                                                x="0.721215"
                                                y="0.443871"
                                                width="40.9209"
                                                height="41.0732"
                                                filterUnits="userSpaceOnUse"
                                                color-interpolation-filters="sRGB"
                                            >
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="BackgroundImageFix"
                                                    result="shape"
                                                />
                                                <feGaussianBlur
                                                    stdDeviation="0.892322"
                                                    result="effect1_foregroundBlur_845_30918"
                                                />
                                            </filter>
                                            <linearGradient
                                                id="paint0_linear_845_30918"
                                                x1="20.7523"
                                                y1="7.03033"
                                                x2="20.9089"
                                                y2="33.4393"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop stop-color="#57D163" />
                                                <stop offset="1" stop-color="#23B33A" />
                                            </linearGradient>
                                        </defs>
                                    </svg> `,
                                    'Whatsapp',
                                    shareWhatsapp,
                                )
                              : ''}
                          ${this.skype && this.skypeReady
                              ? this.li(
                                    html`<svg
                                        width="19"
                                        height="16"
                                        viewBox="0 0 19 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18.2985 0.772295C18.3331 0.559721 18.2553 0.344279 18.0942 0.20603C17.9331 0.0677815 17.7126 0.027394 17.5148 0.0998766L0.924484 6.17884C0.700955 6.26074 0.546639 6.4716 0.532333 6.71468C0.518026 6.95775 0.64649 7.18613 0.858788 7.29504L5.59888 9.72662C5.78906 9.82418 6.01561 9.80971 6.19252 9.6887L10.1273 6.99743L7.77123 10.0189C7.66749 10.152 7.62293 10.3236 7.64841 10.492C7.67388 10.6603 7.76706 10.81 7.90524 10.9045L15.0154 15.7677C15.1827 15.8821 15.3951 15.9017 15.5796 15.8197C15.7641 15.7377 15.8954 15.5653 15.9285 15.3618L18.2985 0.772295Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Skype',
                                    shareSkype,
                                )
                              : ''}
                      </ul>
                  `}
        `;
    }
}

customElements.define('app-web-share', WebShare);

declare global {
    interface HTMLElementTagNameMap {
        'app-web-share': WebShare;
    }
}
