import { gsap } from 'gsap';

function init() {
    const sliderEl = document.querySelector<HTMLElement>('.js-restaurants-detail-menu-gallery');

    if (sliderEl) {
        const slides = Array.from(sliderEl.querySelectorAll<HTMLElement>('.swiper-slide'));

        Array.from(document.querySelectorAll<HTMLElement>('.js-restaurants-detail-menu-slider-change-btn')).forEach(
            (btn) => {
                btn.addEventListener('click', () => {
                    const { slideId } = btn.dataset;
                    const { swiper } = sliderEl as any;
                    const index = slides.findIndex((slide) => slide.dataset.slideId === slideId);

                    if (index > -1) {
                        if (swiper && matchMedia('(min-width: 1200px)').matches) {
                            swiper.slideTo(index);
                        } else {
                            const sliderItem = sliderEl.querySelector<HTMLElement>(
                                '.js-restaurants-detail-menu-gallery-item',
                            );

                            if (sliderItem) {
                                const x = index * (sliderItem.offsetWidth + 10);

                                if (CSS.supports('scroll-behavior', 'smooth')) {
                                    sliderEl.scrollTo({ left: x, behavior: 'smooth' });
                                } else {
                                    gsap.to(sliderEl, {
                                        duration: 0.7,
                                        scrollTo: { x },
                                        ease: 'power3.out',
                                    });
                                }
                            }
                        }
                    }
                });
            },
        );
    }
}

export default { init };
