function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-collapsed-text')).forEach((el) => {
        const textEl = el.querySelector<HTMLElement>('.js-collapsed-text-el');
        const btn = el.querySelector<HTMLElement>('.js-collapsed-text-toggler');
        const rows = el.dataset.rows ? parseInt(el.dataset.rows) : 2;
        const parentTab = btn?.closest('app-tabs');

        if (textEl) {
            textEl.style.setProperty('--rows', `${rows}`);
            let textElScrollHeight = 0;
            let textElHeight = 0;

            const onResize = () => {
                textElScrollHeight = textEl.scrollHeight;
                textElHeight = textEl.offsetHeight;
                textEl.style.setProperty('--height', `${textElScrollHeight}px`);
                textEl.style.setProperty('--collapsed-height', `${textElHeight}px`);
            };

            onResize();
            window.addEventListener('resize', onResize);

            if (textElScrollHeight > textElHeight) {
                el.classList.add('collapsed-text--initialized');
            } else {
                el.classList.remove('collapsed-text--initialized');
            }

            parentTab?.addEventListener('tab-change', () => {
                onResize();

                if (textElScrollHeight > textElHeight) {
                    el.classList.add('collapsed-text--initialized');
                } else {
                    el.classList.remove('collapsed-text--initialized');
                }
            });
        }

        btn?.addEventListener('click', () => {
            const isCollapsed = !el.classList.contains('collapsed-text--expanded');

            if (isCollapsed) {
                el.classList.add('collapsed-text--expanded');
                const btnText = el.dataset.collapsedExpandedText || 'Свернуть';

                if (btn) {
                    if (btn.classList.contains('.link')) {
                        btn.dataset.hover = btnText;
                        const innerEl = btn.querySelector('.link__text');

                        if (innerEl) {
                            innerEl.textContent = btnText;
                        }
                    } else {
                        btn.textContent = btnText;
                    }
                }
            } else {
                el.classList.remove('collapsed-text--expanded');
                const btnText = el.dataset.collapsedCollapsedText || 'Развернуть';

                if (btn) {
                    if (btn.classList.contains('.link')) {
                        btn.dataset.hover = btnText;
                        const innerEl = btn.querySelector('.link__text');

                        if (innerEl) {
                            innerEl.textContent = btnText;
                        }
                    } else {
                        btn.textContent = btnText;
                    }
                }
            }
        });
    });
}

export default { init };
