import { fetchSwiper } from '../dynamic-modules';

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-gallery')).forEach(async (el) => {
        const name = el.dataset.litPopup;
        const titleEl = el.querySelector<HTMLElement>('.js-gallery-title');
        const openers = Array.from(
            document.querySelectorAll<HTMLElement>(`.js-gallery-opener[data-lit-popup-open="${name}"]`),
        );
        const thumbnails = Array.from(el.querySelectorAll<HTMLElement>(`.js-gallery-thumbnail`));
        const images = Array.from(el.querySelectorAll<HTMLElement>(`.js-gallery-image`));
        const nextTrigger = el.querySelector<HTMLElement>('.js-gallery-image-go-next');
        let slider: any;

        const setActiveImageByIndex = (index: number, title?: string) => {
            const currentCounterEl = el.querySelector<HTMLElement>('.js-gallery-current');

            thumbnails.forEach((item) => {
                item.classList.remove('gallery-thumbnail--active');
            });

            thumbnails
                .find((item) => item.dataset.galleryIndex === index.toString())
                ?.classList.add('gallery-thumbnail--active');

            slider?.slideTo(index);

            // images.forEach((img) => {
            //     img.classList.remove('gallery-image--active');
            // });

            // images[index]?.classList.add('gallery-image--active');

            if (currentCounterEl) {
                currentCounterEl.textContent = `${index + 1}`;
            }

            if (titleEl && title) {
                titleEl.textContent = title;
            }
        };

        thumbnails.forEach((thumbnailEl) => {
            thumbnailEl.addEventListener('click', () => {
                const title = thumbnailEl.dataset.galleryTitle || '';
                const indexToShow = thumbnailEl.dataset.galleryIndex ? parseInt(thumbnailEl.dataset.galleryIndex) : -1;

                if (indexToShow > -1) {
                    setActiveImageByIndex(indexToShow, title);
                }
            });
        });

        openers.forEach((openerEl) => {
            openerEl.addEventListener('click', () => {
                const title = openerEl.dataset.galleryTitle || '';
                const indexToShow = openerEl.dataset.galleryIndex ? parseInt(openerEl.dataset.galleryIndex) : -1;

                if (indexToShow > -1) {
                    setActiveImageByIndex(indexToShow, title);
                }
            });
        });

        if (nextTrigger) {
            const { Swiper } = await fetchSwiper();

            slider = new Swiper(nextTrigger, {
                slidesPerView: 1,
                spaceBetween: 0,
                on: {
                    slideChange: (swiper) => {
                        setActiveImageByIndex(
                            swiper.realIndex,
                            thumbnails[swiper.realIndex].dataset.galleryTitle || '',
                        );
                    },
                },
            });

            nextTrigger?.addEventListener('click', () => {
                slider.slideNext();
            });

            // nextTrigger.addEventListener('click', () => {
            //     const currentIndex = images.findIndex((image) => image.classList.contains('gallery-image--active'));
            //     const nextIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;

            //     if (nextIndex > -1) {
            //         swiper.slideNext();
            //         setActiveImageByIndex(nextIndex, thumbnails[nextIndex].dataset.galleryTitle || '');
            //     }
            // });
        }
    });
}

export default { init };
