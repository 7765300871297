import marquee from 'vanilla-marquee';

export default function marqueeImagesInit() {
    const SELECTOR = '.js-marquee-images';
    const marqueeContainers = document.querySelectorAll<HTMLElement>(SELECTOR);
    const map = new WeakMap();

    const onMouseEnter = (event: MouseEvent) => {
        const eventTarget = event.target as HTMLElement;
        const container = eventTarget.closest<HTMLElement>('.js-marquee-wrapper');

        if (container) {
            const el = container.querySelector<HTMLElement>(SELECTOR);
            if (el) {
                const instance = map.get(el);
                instance?.resume();
            }
        }
    };

    const onMouseLeave = (event: MouseEvent) => {
        const eventTarget = event.target as HTMLElement;
        const container = eventTarget.closest<HTMLElement>('.js-marquee-wrapper');

        if (container) {
            const el = container.querySelector<HTMLElement>(SELECTOR);
            if (el) {
                const instance = map.get(el);
                instance?.pause();
            }
        }
    };

    marqueeContainers.forEach((container) => {
        container.style.width = `${container.offsetWidth}px`;
        const instance = new marquee(container, {
            duplicated: true,
            delayBeforeStart: 0,
            gap: 8,
        });
        setTimeout(() => {
            instance.refresh();
            instance.pause();
            container.classList.add('is-visible');
        }, 10);
        map.set(container, instance);
        const wrapper = container.closest<HTMLElement>('.js-marquee-wrapper');

        if (wrapper) {
            wrapper.addEventListener('mouseenter', onMouseEnter);
            wrapper.addEventListener('mouseleave', onMouseLeave);
        }
    });
}
