function init(container: HTMLElement | Document = document) {
    const typesList = container.querySelector<HTMLElement>('.js-dishes-nav-types-list');
    const typesNavButtonsContainer = container.querySelector<HTMLElement>('.js-dishes-nav-buttons');
    const typesNavBtnPrev = container.querySelector<HTMLButtonElement>('button.js-dishes-nav-btn-prev');
    const typesNavBtnNext = container.querySelector<HTMLButtonElement>('button.js-dishes-nav-btn-next');

    const onResize = () => {
        if (typesNavButtonsContainer && typesList) {
            typesNavButtonsContainer.hidden = typesList.scrollWidth <= typesList.offsetWidth;
        }
    };

    onResize();
    window.addEventListener('resize', onResize);

    const setButtonsState = () => {
        if (typesList) {
            if (typesNavBtnPrev) {
                typesNavBtnPrev.disabled = typesList.scrollLeft === 0;
            }

            if (typesNavBtnNext) {
                typesNavBtnNext.disabled = typesList.scrollLeft === typesList.scrollWidth - typesList.offsetWidth;
            }
        }
    };

    setButtonsState();

    typesList?.addEventListener('scroll', setButtonsState);

    if (typesNavBtnPrev) {
        typesNavBtnPrev.addEventListener('click', () => {
            // gsap.to(typesList, {
            //     scrollTo: {
            //         x: `-=${(typesList?.offsetWidth || 0) / 2}`,
            //     },
            //     duration: 1.5,
            //     overwrite: true,
            //     ease: 'power2.out',
            // });
            typesList?.scrollTo({ left: typesList.scrollLeft - typesList.offsetWidth / 2, behavior: 'smooth' });
        });
    }

    if (typesNavBtnNext) {
        typesNavBtnNext.addEventListener('click', () => {
            // gsap.to(typesList, {
            //     scrollTo: {
            //         x: `+=${(typesList?.offsetWidth || 0) / 2}`,
            //     },
            //     duration: 1.5,
            //     overwrite: true,
            //     ease: 'power2.out',
            // });
            typesList?.scrollTo({ left: typesList.scrollLeft + typesList.offsetWidth / 2, behavior: 'smooth' });
        });
    }
}

export default { init };
