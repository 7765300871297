import { gsap } from 'gsap';
import { clamp } from '../utils/math';

function init(el: HTMLElement | Document = document, closeFn?: () => void, mediaQuery?: string) {
    const handle = el.querySelector<HTMLElement>('.js-mobile-app-like-modal-handle');
    const innerEl = el.querySelector<HTMLElement>('.js-mobile-app-like-modal-inner');
    const container = el.querySelector<HTMLElement>('.lit-popup-container');
    let tween: gsap.core.Tween | null;

    let touchStartY = 0;
    let touchY = 0;
    let translateAllowed = false;
    let onCompleteTimeout: NodeJS.Timeout;

    const onTouchStart = (event: TouchEvent) => {
        clearTimeout(onCompleteTimeout);

        if (container) {
            container.style.overflow = 'hidden';
        }

        if (tween) {
            tween.kill();
            tween = null;
        }

        if (!mediaQuery || matchMedia(mediaQuery).matches) {
            if (event instanceof TouchEvent) {
                translateAllowed = true;
                touchStartY = event.touches[0].clientY;

                if (innerEl) {
                    innerEl.style.willChange = 'transform';
                }
            }

            document.addEventListener('touchmove', onTouchMove);
            document.addEventListener('touchend', onTouchEnd);
        }
    };

    const onTouchMove = (event: TouchEvent) => {
        if (!mediaQuery || matchMedia(mediaQuery).matches) {
            if (translateAllowed && innerEl && event instanceof TouchEvent) {
                touchY = clamp((event.changedTouches[0].clientY - touchStartY) / 3, 0, 200);
                innerEl.style.transform = `translate3d(0px, ${touchY}px, 0px)`;
            }
        }
    };

    const onTouchEnd = () => {
        if (container) {
            container.style.overflow = '';
        }

        if (!mediaQuery || matchMedia(mediaQuery).matches) {
            if (translateAllowed) {
                if (touchY > 20) {
                    closeFn?.();
                    onCompleteTimeout = setTimeout(() => {
                        gsap.set(innerEl, { clearProps: 'all' });
                    }, 300);
                } else if (innerEl) {
                    tween = gsap.to(innerEl, {
                        duration: 0.5,
                        y: 0,
                        overwrite: true,
                        onComplete: () => {
                            tween?.kill();
                            tween = null;
                        },
                    });
                }

                translateAllowed = false;
            }
        }

        document.removeEventListener('touchmove', onTouchMove);
        document.removeEventListener('touchend', onTouchEnd);
    };

    if (handle) {
        handle.addEventListener('touchstart', onTouchStart);
    }
}

export default { init };
