import { gsap } from 'gsap';
import { lockBodyScroll, unlockBodyScroll } from '../utils/dom';
import { clamp } from '../utils/math';

const ASIDE_POPUP_SELECTOR = '.js-inner-page-aside';
const ASIDE_POPUP_VISIBLE_CLASS = 'is-visible';
const ASIDE_TOGGLER_SELECTOR = '.js-inner-page-aside-toggle-btn';

function init() {
    const aside = document.querySelector(ASIDE_POPUP_SELECTOR);
    const toggler = document.querySelector(ASIDE_TOGGLER_SELECTOR);
    const overlay = aside?.querySelector('.js-inner-page-aside-overlay');
    const handle = aside?.querySelector('.js-inner-page-aside-handle');
    const container = aside?.querySelector<HTMLElement>('.js-inner-page-aside-inner-wrapper');
    const innerEl = aside?.querySelector<HTMLElement>('.js-inner-page-aside-inner');

    let tween: gsap.core.Tween | null;
    let timeout: NodeJS.Timeout;
    let onCompleteTimeout: NodeJS.Timeout;

    let touchStartY = 0;
    let touchY = 0;
    let translateAllowed = false;

    const openPopup = () => {
        clearTimeout(timeout);

        if (innerEl) {
            innerEl.style.transform = '';
        }

        aside?.classList.add(ASIDE_POPUP_VISIBLE_CLASS);
        lockBodyScroll();
    };

    const closePopup = () => {
        aside?.classList.remove(ASIDE_POPUP_VISIBLE_CLASS);
        unlockBodyScroll();
    };

    overlay?.addEventListener('click', closePopup);
    toggler?.addEventListener('click', () => {
        if (aside) {
            if (aside.classList.contains(ASIDE_POPUP_VISIBLE_CLASS)) {
                closePopup();
            } else {
                openPopup();
            }
        }
    });

    // if (handle) {
    //     handle.addEventListener('touchstart', (event) => {
    //         if (event instanceof TouchEvent) {
    //             translateAllowed = true;
    //             touchStartY = event.touches[0].clientY;

    //             if (innerEl) {
    //                 innerEl.style.transition = 'none';
    //                 innerEl.style.willChange = 'transform';
    //             }
    //         }
    //     });

    //     handle.addEventListener('touchmove', (event) => {
    //         if (translateAllowed && innerEl && event instanceof TouchEvent) {
    //             if (tween) {
    //                 tween.pause();
    //                 tween = null;
    //             }

    //             touchY = clamp((event.changedTouches[0].clientY - touchStartY) / 3, 0, 200);
    //             innerEl.style.transform = `translate3d(0px, ${touchY}px, 0px)`;
    //         }
    //     });

    //     handle.addEventListener('touchend', () => {
    //         if (translateAllowed) {
    //             if (touchY > 20) {
    //                 closePopup();

    //                 if (innerEl) {
    //                     innerEl.style.willChange = '';
    //                 }
    //             } else if (innerEl) {
    //                 tween = gsap.to(innerEl, {
    //                     duration: 0.5,
    //                     y: 0,
    //                     overwrite: true,
    //                     onComplete: () => {
    //                         gsap.set(innerEl, { clearProps: 'all' });
    //                         tween = null;
    //                     },
    //                 });
    //             }

    //             touchStartY = 0;
    //             translateAllowed = false;
    //         }
    //     });
    // }

    //

    const onTouchStart = (event: TouchEvent) => {
        clearTimeout(onCompleteTimeout);

        if (container) {
            container.style.overflow = 'hidden';
        }

        if (tween) {
            tween.kill();
            tween = null;
        }

        translateAllowed = true;
        touchStartY = event.touches[0].clientY;

        if (innerEl) {
            innerEl.style.willChange = 'transform';
        }

        document.addEventListener('touchmove', onTouchMove);
        document.addEventListener('touchend', onTouchEnd);
    };

    const onTouchMove = (event: TouchEvent) => {
        if (translateAllowed && innerEl && event instanceof TouchEvent) {
            touchY = clamp((event.changedTouches[0].clientY - touchStartY) / 3, 0, 200);
            innerEl.style.transform = `translate3d(0px, ${touchY}px, 0px)`;
        }
    };

    const onTouchEnd = () => {
        if (container) {
            container.style.overflow = '';
        }

        if (translateAllowed) {
            if (touchY > 20) {
                closePopup();
                onCompleteTimeout = setTimeout(() => {
                    if (innerEl) {
                        gsap.set(innerEl, { clearProps: 'all' });
                    }
                }, 300);
            } else if (innerEl) {
                tween = gsap.to(innerEl, {
                    duration: 0.5,
                    y: 0,
                    overwrite: true,
                    onComplete: () => {
                        tween?.kill();
                        tween = null;
                    },
                });
            }

            translateAllowed = false;
        }

        document.removeEventListener('touchmove', onTouchMove);
        document.removeEventListener('touchend', onTouchEnd);
    };

    if (handle) {
        handle.addEventListener('touchstart', onTouchStart);
    }

    aside?.addEventListener('click', (event) => {
        if (event.target instanceof HTMLElement && !event.target.closest('.js-inner-page-aside-inner')) {
            closePopup();
        }
    });
}

export default { init };
