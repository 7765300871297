import { fetchSwiper } from '../dynamic-modules';

function init() {
    const elements = Array.from(document.querySelectorAll<HTMLElement>('.js-restaurants-detail-content-gallery'));
    elements.forEach(async (slider) => {
        const switcher = document.querySelector<HTMLInputElement>('input.js-detail-short-switcher');
        const slides = Array.from(slider.querySelectorAll('.swiper-slide'));
        const prevBtn = slider.querySelector<HTMLElement>('.js-restaurants-detail-content-gallery-btn-prev');
        const nextBtn = slider.querySelector<HTMLElement>('.js-restaurants-detail-content-gallery-btn-next');

        const onSlideChange = (swiper: any) => {
            if (prevBtn) {
                prevBtn.hidden = swiper.realIndex === 0;
            }

            if (nextBtn) {
                nextBtn.hidden = swiper.realIndex === slides.length - 2;
            }
        };

        if (slides.length > 2) {
            const { Swiper } = await fetchSwiper();
            const swiper = new Swiper(slider, {
                slidesPerView: 'auto',
                spaceBetween: 0,
                allowTouchMove: false,
                on: {
                    slideChange: (swiper) => onSlideChange(swiper),
                },
            });

            const setSliderState = () => {
                if (switcher) {
                    if (matchMedia('(min-width: 1200px)').matches) {
                        swiper.el.scrollTo(0, 0);
                    }

                    if (switcher.checked && matchMedia('(min-width: 1200px)').matches) {
                        swiper.enable();
                        swiper.update();
                    } else {
                        swiper.disable();
                    }
                }
            };

            onSlideChange(swiper);

            setSliderState();
            window.addEventListener('resize', setSliderState);

            const detailShortSwitcher = document.querySelector<HTMLInputElement>('input.js-detail-short-switcher');
            detailShortSwitcher?.addEventListener('change', () => {
                swiper.el.scrollTo(0, 0);
                swiper.slideTo(0);
            });

            prevBtn?.addEventListener('click', () => {
                swiper.slidePrev();
            });

            nextBtn?.addEventListener('click', () => {
                swiper.slideNext();
            });

            switcher?.addEventListener('change', setSliderState);
        }
    });
}

export default { init };
