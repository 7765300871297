import delegate from 'delegate';

const checkSVG = `
<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 1.5L4.24742 9.5L1 6.45705" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const crossSVG = `
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 1L1 13M1 1L13 13" stroke="#0A0A29" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

function createMessage({ icon, text, isError }: { icon: string; text: string; isError?: boolean }) {
    const messageEl = document.createElement('div');
    messageEl.className = 'copy-link-result' + (isError ? ' is-error' : '');
    messageEl.innerHTML = `
        <div class="copy-link-result__icon">${icon}</div>
        <div class="text-button-3 copy-link-result__text">${text}</div>
    `;
    return messageEl;
}

const map = new WeakMap<Element, NodeJS.Timeout>();

function init(container: HTMLElement | Document = document) {
    delegate(container, '.js-copy', 'click', (event: any) => {
        const target = event.delegateTarget as HTMLElement;
        const textToCopy = target.dataset.copyValue;

        if (textToCopy) {
            const container = target.closest<HTMLElement>('.js-copy-link');

            if (container) {
                const currentTimeout = map.get(container);

                if (currentTimeout) {
                    clearTimeout(currentTimeout);
                    const currentMessageEl = container.querySelector('.copy-link-result');
                    currentMessageEl?.remove();
                }
            }

            try {
                navigator.clipboard.writeText(textToCopy).then(
                    () => {
                        if (container) {
                            const messageEl = createMessage({ icon: checkSVG, text: 'Скопировано' });
                            container.appendChild(messageEl);
                            const timeout = setTimeout(() => {
                                messageEl.classList.add('is-hidden');
                                messageEl.addEventListener(
                                    'animationend',
                                    () => {
                                        container?.removeChild(messageEl);
                                    },
                                    { once: true },
                                );
                            }, 3000);
                            map.set(container, timeout);
                        }
                    },
                    () => {
                        if (container) {
                            const messageEl = createMessage({
                                icon: crossSVG,
                                text: 'Не удалось скопировать',
                                isError: true,
                            });
                            container.appendChild(messageEl);
                            const timeout = setTimeout(() => {
                                messageEl.classList.add('is-hidden');
                                messageEl.addEventListener(
                                    'animationend',
                                    () => {
                                        container?.removeChild(messageEl);
                                    },
                                    { once: true },
                                );
                            }, 3000);
                            map.set(container, timeout);
                        }
                    },
                );
            } catch (err) {
                if (container) {
                    const messageEl = createMessage({ icon: crossSVG, text: 'Не удалось скопировать', isError: true });
                    container?.appendChild(messageEl);
                    const timeout = setTimeout(() => {
                        messageEl.classList.add('is-hidden');
                        messageEl.addEventListener(
                            'animationend',
                            () => {
                                container?.removeChild(messageEl);
                            },
                            { once: true },
                        );
                    }, 3000);
                    map.set(container, timeout);
                }
            }
        }
    });
}

export default { init };
