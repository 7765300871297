import { clamp } from '../utils/math';
import { viewport } from '../utils/viewport';

function init() {
    const bookRestEl = document.querySelector<HTMLElement>('.js-inner-page__aside__book-rest');

    if (bookRestEl) {
        const banner = document.querySelector<HTMLElement>('.js-inner-page__aside-block-banner');
        const innerPage = document.querySelector<HTMLElement>('.inner-page');
        let threshold = 0;
        let bannerHeight = 0;

        if (innerPage && banner) {
            const onResize = () => {
                const bookRestHeight = bookRestEl.offsetHeight;
                banner.style.setProperty('--book-rest-height', `${bookRestHeight}px`);
                bannerHeight = banner.offsetHeight;
                threshold = innerPage.offsetTop + innerPage.offsetHeight - bannerHeight - bookRestHeight - 40;
            };

            onResize();
            window.addEventListener('resize', onResize);

            const onScroll = () => {
                if (matchMedia('(max-width: 1280px)').matches) {
                    bookRestEl.style.position = ``;
                    bookRestEl.style.top = '';
                } else {
                    const { scrollY } = window;

                    if (scrollY > threshold) {
                        bookRestEl.style.position = `absolute`;
                        bookRestEl.style.top = `${threshold - 11}px`;
                    } else {
                        bookRestEl.style.position = `sticky`;
                        bookRestEl.style.top = '5px';
                    }
                }
            };

            onScroll();
            document.addEventListener('scroll', onScroll);
        }
    }
}

export default { init };
