import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

export default function mapPageInit() {
    const mapPageEl = document.querySelector('.js-map-page');

    if (mapPageEl) {
        const filtersPopup = document.querySelector<LitPopupElement>('app-lit-popup[data-lit-popup="filters-popup"]');
        const footerContainer = document.querySelector<HTMLElement>('.js-footer-container');
        const footerWrapper = document.querySelector<HTMLElement>('.js-footer-wrapper');
        const footerSwitcher = document.querySelector<HTMLElement>('.js-footer-switcher');
        const breadcrumbs = document.querySelector<HTMLElement>('.js-breadcrumbs');

        if (filtersPopup && window.matchMedia('(min-width: 1280px)').matches) {
            filtersPopup.open();
        }

        if (breadcrumbs) {
            breadcrumbs.style.width = 'auto';
            setTimeout(() => {
                breadcrumbs.style.setProperty('--full-width', `${breadcrumbs.offsetWidth}px`);
                breadcrumbs.style.width = '';
            }, 1);
        }

        if (footerWrapper) {
            footerWrapper.classList.add('map-page-footer');
        }

        if (footerContainer && footerWrapper) {
            footerContainer.style.setProperty('--full-height', `${footerContainer.offsetHeight}px`);
            footerWrapper.classList.add('is-hidden');

            if (footerSwitcher) {
                footerSwitcher.addEventListener('click', () => {
                    footerWrapper.classList.toggle('is-hidden');
                });
            }
        }
    }
}
