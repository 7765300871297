function init() {
    const bookRestEl = document.querySelector<HTMLElement>('.js-inner-page__aside__book-rest');

    if (bookRestEl) {
        document.addEventListener('scroll', () => {
            if (window.scrollY > 20) {
                bookRestEl.classList.add('with-shadow');
            } else {
                bookRestEl.classList.remove('with-shadow');
            }
        });
    }
}

export default { init };
