function init() {
    const searchPopup = document.querySelector<HTMLFormElement>('app-lit-popup.js-search-popup');
    const searchFormInput = searchPopup?.querySelector<HTMLInputElement>('input.js-search-input');
    let timeout: NodeJS.Timeout;

    searchPopup?.addEventListener('open', () => {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            searchFormInput?.focus();
        }, 100);
    });
}

export default { init };
