import { fetchSwiper } from '../dynamic-modules';

export async function initSliderInReviewCard() {
    const reviewCardsSlider = Array.from(document.querySelectorAll<HTMLElement>('.js-slider-in-review-card'));
    const { Swiper, Pagination } = await fetchSwiper();
    reviewCardsSlider.forEach((slider) => {
        const nextTrigger = slider.querySelector('.js-slider-in-review-card-next-trigger');
        const slides = Array.from(slider.querySelectorAll('.js-slider-in-review-card-image'));

        if (slides.length > 1) {
            const bulletsContainer = slider.querySelector<HTMLElement>('.js-slider-in-review-bullets-container');
            const swiper = new Swiper(slider, {
                modules: [Pagination],
                slidesPerView: 1,
                spaceBetween: 10,
                loop: true,
                pagination: {
                    el: bulletsContainer,
                    type: 'bullets',
                    clickable: true,
                    renderBullet: (_, className) => {
                        return `<button class="bullet ${className}"></button>`;
                    },
                },
            });

            nextTrigger?.addEventListener('click', () => {
                swiper.slideNext();
            });
        }
    });
}
