export default (element: HTMLElement, offset = 0) => {
    const elementWidth = element.offsetWidth;
    const elementRect = element.getBoundingClientRect();
    const { left } = elementRect;
    const diff = left + elementWidth - window.innerWidth;

    if (diff > 0) {
        element.style.left = `${-(diff + offset)}px`;
    }
};
