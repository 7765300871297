import debounce from 'lodash.debounce';

const FILLED_CLASS = 'search--input-filled';

function init() {
    const searchForm = document.querySelector<HTMLFormElement>('form.js-search');

    if (searchForm) {
        const searchFormInput = searchForm.querySelector<HTMLInputElement>('input.js-search-input');
        const searchFormClearBtn = searchForm.querySelector<HTMLElement>('.js-search-clear-btn');
        const searchFormTipButtons = Array.from(searchForm.querySelectorAll<HTMLElement>('.js-search-tip-btn'));

        searchFormInput?.addEventListener(
            'input',
            debounce(() => {
                if (searchFormInput) {
                    if (searchFormInput.value.trim().length > 0) {
                        searchForm.classList.add(FILLED_CLASS);
                    } else {
                        searchForm.classList.remove(FILLED_CLASS);
                    }
                }
            }, 100),
        );

        searchFormClearBtn?.addEventListener('click', (event: Event) => {
            event.preventDefault();

            if (searchFormInput) {
                searchFormInput.value = '';
                // searchForm.classList.remove(FILLED_CLASS);
                searchFormInput.dispatchEvent(new Event('input'));
                searchFormInput.focus();
            }
        });

        searchFormTipButtons.forEach((btn) => {
            btn.addEventListener('click', () => {
                const { value } = btn.dataset;

                if (value && searchFormInput) {
                    searchFormInput.value = value;
                    searchFormInput.dispatchEvent(new Event('input'));
                    searchFormInput.focus();
                }
            });
        });
    }
}

export default { init };
