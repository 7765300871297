function init(container: HTMLElement | Document = document) {
    const switcher = container.querySelector<HTMLInputElement>('input.js-detail-short-switcher');
    const hideWhenShortElements = Array.from(
        container.querySelectorAll<HTMLElement>('.js-restaurant-detail-content-hide-when-short'),
    );
    const showWhenShortElements = Array.from(
        container.querySelectorAll<HTMLElement>('.js-restaurant-detail-content-show-when-short'),
    );

    const onChange = () => {
        if (switcher) {
            if (switcher.checked) {
                document.documentElement.classList.add('detail-short-view');
            } else {
                document.documentElement.classList.remove('detail-short-view');
            }

            hideWhenShortElements.forEach((el) => {
                el.hidden = switcher.checked;
            });

            showWhenShortElements.forEach((el) => {
                el.hidden = !switcher.checked;
            });
        }
    };

    onChange();
    switcher?.addEventListener('change', onChange);
}

export default { init };
